import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVICES_API } from '@amica-sso/data';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Logout client and destroy session
   */
  public logout(): Observable<void> {
    return this._httpClient.get<void>(`${this._servicesApi}/platform/logout`);
  }
}
