import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { delay, distinctUntilChanged, map, startWith, takeWhile } from 'rxjs/operators';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { state, style, trigger } from '@angular/animations';
import { AnimateService } from './animate.service';
// Animations
import {
  beat,
  bounce,
  flip,
  headShake,
  heartBeat,
  jello,
  pulse,
  rubberBand,
  shake,
  swing,
  tada,
  wobble,
} from './attention-seekers';
import { bounceIn, bumpIn, fadeIn, flipIn, jackInTheBox, landing, rollIn, zoomIn } from './entrances';
import { bounceOut, fadeOut, hinge, rollOut, zoomOut } from './exits';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type appAnimateSpeed = 'slower' | 'slow' | 'normal' | 'fast' | 'faster';
export type appAnimations =
  // Attention seekers
  | 'beat'
  | 'bounce'
  | 'flip'
  | 'headShake'
  | 'heartBeat'
  | 'jello'
  | 'pulse'
  | 'rubberBand'
  | 'shake'
  | 'swing'
  | 'tada'
  | 'wobble'
  // Entrances
  | 'bumpIn'
  | 'bounceIn'
  | 'bounceInDown'
  | 'bounceInLeft'
  | 'bounceInUp'
  | 'bounceInRight'
  | 'fadeIn'
  | 'fadeInRight'
  | 'fadeInLeft'
  | 'fadeInUp'
  | 'fadeInDown'
  | 'flipInX'
  | 'flipInY'
  | 'jackInTheBox'
  | 'landing'
  | 'rollIn'
  | 'zoomIn'
  | 'zoomInDown'
  | 'zoomInLeft'
  | 'zoomInUp'
  | 'zoomInRight'
  // Exits
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutUp'
  | 'bounceOutRight'
  | 'bounceOutLeft'
  | 'fadeOut'
  | 'fadeOutRight'
  | 'fadeOutLeft'
  | 'fadeOutDown'
  | 'fadeOutUp'
  | 'hinge'
  | 'rollOut'
  | 'zoomOut'
  | 'zoomOutDown'
  | 'zoomOutRight'
  | 'zoomOutUp'
  | 'zoomOutLeft'
  // None
  | 'none';

@Component({
  selector: '[appAnimate]',
  template: '<ng-content></ng-content>',
  animations: [
    trigger('animate', [
      // Attention seekers
      ...beat,
      ...bounce,
      ...flip,
      ...headShake,
      ...heartBeat,
      ...jello,
      ...pulse,
      ...rubberBand,
      ...shake,
      ...swing,
      ...tada,
      ...wobble,
      // Entrances
      ...bumpIn,
      ...bounceIn,
      ...fadeIn,
      ...flipIn,
      ...jackInTheBox,
      ...landing,
      ...rollIn,
      ...zoomIn,
      // Exits
      ...bounceOut,
      ...fadeOut,
      ...hinge,
      ...rollOut,
      ...zoomOut,
      // None
      state('none', style('*')),
      state('idle-none', style('*')),
    ]),
  ],
  standalone: true,
})
@UntilDestroy()
export class AnimateComponent implements OnInit {
  // Animating properties
  public animating = false;
  public animated = false;
  @HostBinding('@animate')
  public trigger: any;
  /** Selects the animation to be played */
  @Input('appAnimate')
  public animate?: appAnimations;
  /** Emits at the end of the animation */
  @Output()
  public readonly start = new EventEmitter<void>();
  /** Emits at the end of the animation */
  @Output()
  public readonly done = new EventEmitter<void>();
  public paused = false;
  public once = false;
  private replay$ = new Subject<boolean>();
  // Animating parameters
  private timing: string | null = null;
  private _delay: string | null = null;
  private threshold = 0;

  get delay(): string | null {
    return this._delay;
  }

  /** Delays the animation */
  @Input()
  set delay(delay: string | null) {
    // Coerces the input into a number first
    const value = coerceNumberProperty(delay, 0);
    if (value) {
      // Turns a valid number into a ms delay
      this._delay = `${value}ms`;
    } else {
      // Test the string for a valid delay combination
      this._delay = /^\d+(?:ms|s)$/.test(delay || '') ? delay : '';
    }
  }

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _animateService: AnimateService,
    private readonly _renderer2: Renderer2,
  ) {}

  @HostBinding('@.disabled')
  private _disabled = false;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  /** Speeds up or slows down the animation */
  @Input()
  set speed(speed: appAnimateSpeed) {
    // Turns the requested speed into a valid timing
    this.timing =
      {
        slower: '3s',
        slow: '2s',
        normal: '1s',
        fast: '300ms',
        faster: '200ms',
      }[speed || 'normal'] || '1s';
  }

  /** When true, keeps the animation idle until the next replay triggers */
  @Input('paused')
  set pauseAnimation(value: boolean) {
    this.paused = coerceBooleanProperty(value);
  }

  /** When defined, triggers the animation on element scrolling in the viewport by the specified amount. Amount defaults to 50% when not specified */
  @Input('aos')
  set enableAOS(value: number) {
    this.threshold = coerceNumberProperty(value, 0.5);
  }

  /** When true, triggers the animation on element scrolling in the viewport */
  @Input('once')
  set aosOnce(value: boolean) {
    this.once = coerceBooleanProperty(value);
  }

  /** Replays the animation */
  @Input()
  set replay(replay: any) {
    // Re-triggers the animation again on request (skipping the very fist value)
    if (!!this.trigger && coerceBooleanProperty(replay)) {
      this.trigger = this.idle;
      this.replay$.next(true);
    }
  }

  private get idle() {
    return { value: `idle-${this.animate}` };
  }

  private get play() {
    const params: any = {};
    // Builds the params object, so, leaving to the default values when undefined
    if (this.timing) {
      params['timing'] = this.timing;
    }
    if (this._delay) {
      params['delay'] = this._delay;
    }

    return { value: this.animate, params };
  }

  @HostListener('@animate.start')
  public animationStart() {
    this.animating = true;
    this.animated = false;
    this.start.emit();
  }

  @HostListener('@animate.done')
  public animationDone() {
    this.animating = false;
    this.animated = true;
    this.done.emit();

    /**
     * Removes spurious 'animation' style from the element once done with the animation.
     * This behaviour has been observed when running on iOS devices where for some reason
     * the animation engine do not properly clean-up the animation style using cubic-bezier()
     * as its timing function. The issue do not appear with ease-in/out and others.
     * */
    this._renderer2.removeStyle(this._elementRef.nativeElement, 'animation');
  }

  public ngOnInit(): void {
    // Triggers the animation based on the input flags
    this.replay$
      .pipe(
        untilDestroyed(this),
        // Waits the next round to re-trigger
        delay(0),

        // Triggers immediately when not paused
        startWith(!this.paused),

        // Builds the AOS observable from the common service
        this._animateService.trigger(this._elementRef, this.threshold),

        // Stop taking the first on trigger when aosOnce is set
        takeWhile((trigger) => !trigger || !this.once, true),

        // Maps the trigger into animation states
        map((trigger) => (trigger ? this.play : this.idle)),

        // Always start with idle
        startWith(this.idle),

        // Eliminates multiple triggers
        distinctUntilChanged(),

        // Triggers the animation to play or to idle
      )
      .subscribe((trigger) => (this.trigger = trigger));
  }
}
