export enum RouteSlug {
  MAIN = '',
  SITE_UNDER_CONSTRUCTION = 'site-under-construction',
  REGISTER = 'register',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTRATION = 'registration',
  ACTIVATE_ACCOUNT = 'activate',
  REMIND_PASSWORD = 'remind-password',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_PASSWORD_AFTER_LOGIN = 'change-password-after-login',
  ACCOUNT = 'account',
  UNSUBSCRIBE = 'usun-zgody',
  EMAIL_PREVIEW = 'podglad-wiadomosci',
  COOKIES = 'cookies',
  NOT_FOUND_PAGE = '404',
  SOMETHING_WENT_WRONG = 'something-went-wrong',
  REDIRECT_TO_CLIENT_ID = 'redirect-to-client-id',
  SSR_REDIRECT = 'ssr-redirect',
}
