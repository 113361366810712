import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVICES_API } from '@amica-sso/data';
import { IDefaultClientResponse } from '../models/default-client-response';

@Injectable({
  providedIn: 'root',
})
export class DefaultClientService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Get default oauth2 client information
   */
  public getDefaultClientInformation(): Observable<IDefaultClientResponse> {
    return this._httpClient.get<IDefaultClientResponse>(`${this._servicesApi}/platform/oauth2/default-client`);
  }
}
