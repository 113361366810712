import { Routes } from '@angular/router';
import { RouteSlug } from './models/route-slug.enum';

export const APP_ROUTES: Routes = [
  {
    path: RouteSlug.SSR_REDIRECT,
    loadComponent: () =>
      import('./ssr-redirect/ssr-redirect.component').then(({ SsrRedirectComponent }) => SsrRedirectComponent),
  },
  {
    path: '',
    loadChildren: () => import('./pages/core.routing').then(({ CORE_ROUTES }) => CORE_ROUTES),
  },
];
