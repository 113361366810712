import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { fromIntersectionObserver, IntersectionStatus } from './from-intersection-observer';

@Directive({ selector: '[appIntersectionObserver]', standalone: true })
@UntilDestroy()
export class IntersectionObserverDirective implements AfterViewInit {
  @Output()
  public readonly visibilityChange = new EventEmitter<IntersectionStatus>();
  @Input()
  public intersectionDebounce = 0;
  @Input()
  public intersectionRootMargin = '0px';
  @Input()
  public intersectionRoot?: HTMLElement;
  @Input()
  public intersectionThreshold?: number | number[];

  constructor(
    private readonly _elementRef: ElementRef,
    @Inject(PLATFORM_ID)
    protected readonly _platformId: object,
  ) {}

  public ngAfterViewInit(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    const element = this._elementRef.nativeElement;
    const config: IntersectionObserverInit = {
      root: this.intersectionRoot,
      rootMargin: this.intersectionRootMargin,
      threshold: this.intersectionThreshold,
    };

    fromIntersectionObserver(element, config, this.intersectionDebounce)
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.visibilityChange.emit(status);
      });
  }
}
