import { Directive, ElementRef, Inject, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appPasswordToggle]',
  standalone: true,
})
@UntilDestroy()
export class PasswordToggleDirective {
  private _shown = false;

  constructor(
    private readonly _elementRef: ElementRef,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _renderer2: Renderer2,
  ) {
    const span = _document.createElement('span');
    span.className = 'toggle-password';
    span.innerHTML = 'show';
    span.innerHTML = '<img src="assets/images/eye.svg" alt="eye">';
    span.addEventListener('click', () => {
      this.toggle(span);
    });
    this._renderer2.insertBefore(this._elementRef.nativeElement.parentNode, span, this._elementRef.nativeElement);
  }

  public toggle(span: HTMLElement): void {
    console.warn('this._shown', this._shown);
    this._shown = !this._shown;
    if (this._shown) {
      this._elementRef.nativeElement.setAttribute('type', 'text');
      span.innerHTML = '<img src="assets/images/eye-visible.svg" alt="eye">';
    } else {
      this._elementRef.nativeElement.setAttribute('type', 'password');
      span.innerHTML = '<img src="assets/images/eye.svg" alt="eye">';
    }
  }
}
