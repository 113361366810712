import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appInputValidator]',
  standalone: true,
})
@UntilDestroy()
export class InputValidatorDirective implements OnInit, AfterViewInit, DoCheck {
  @Input('appInputValidator')
  public control?: AbstractControl;
  @Input()
  public statusIconVisible = true;
  @Input()
  public inputType: string | null = null;
  private _valCssClassSubj = new Subject<string>();
  private _floatLabelSubj = new Subject<string>();
  private _touched = false;
  private readonly _id = InputValidatorDirective._buildId();

  constructor(
    private readonly _renderer2: Renderer2,
    private readonly _elementRef: ElementRef,
    @Inject(DOCUMENT)
    private readonly _document: Document,
  ) {}

  private static _buildId(): string {
    return '_' + Math.random().toString(36).substr(2, 12);
  }

  public ngAfterViewInit(): void {
    this._floatLabelSubj.next(this.control?.value);
  }

  public ngOnInit(): void {
    this._floatLabelSubj.next(this.control?.value);
    this._initSubscriptions();
  }

  public ngDoCheck(): void {
    if (this._touched !== this.control?.touched) {
      this._touched = this.control?.touched || false;
      this._valCssClassSubj.next(this.control?.value);
    }
  }

  @HostListener('focusout', ['$event.target'])
  public onFocusout() {
    this._floatLabelSubj.next(this.control?.value);
    this._valCssClassSubj.next(this.control?.value);
  }

  private _initSubscriptions(): void {
    const _removePreviouslyAddedIconIfExists = () => {
      const icon: HTMLElement | null = this._document.getElementById(this._id);
      if (icon) {
        this._renderer2.removeChild(this._elementRef.nativeElement.parentNode, icon, this._elementRef.nativeElement);
      }
    };

    const _addIcon = (innerHTML: string, cssClass: string) => {
      const span: HTMLSpanElement = this._document.createElement('span');
      if (this.inputType === 'password') {
        span.className = `${cssClass} ams-password`;
      } else {
        span.className = cssClass;
      }
      span.id = this._id;
      span.innerHTML = innerHTML;
      this._renderer2.insertBefore(this._elementRef.nativeElement.parentNode, span, this._elementRef.nativeElement);
    };
    const _addIcons = (control: AbstractControl) => {
      if (!this.statusIconVisible) {
        return;
      }
      _removePreviouslyAddedIconIfExists();
      if (control.valid) {
        _addIcon(`<img src="assets/images/checkmark-green.svg" alt="checkmark">`, 'success-icon');
      } else {
        _addIcon('!', 'error-icon');
      }
    };
    const _handleErrorClass = (control: AbstractControl) => {
      if (control.valid) {
        this._renderer2.removeClass(this._elementRef.nativeElement, 'status-danger');
      } else {
        this._renderer2.addClass(this._elementRef.nativeElement, 'status-danger');
      }
    };

    this._valCssClassSubj.pipe(untilDestroyed(this), debounceTime(100), distinctUntilChanged()).subscribe(() => {
      if (!this.control?.touched && !this.control?.dirty) {
        return;
      }
      _handleErrorClass(this.control);
      _addIcons(this.control);
    });

    this._floatLabelSubj.pipe(untilDestroyed(this), debounceTime(25), distinctUntilChanged()).subscribe((value) => {
      this._handleValueChange(value);
    });

    this.control?.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged(), debounceTime(100))
      .subscribe((value) => {
        this._handleValueChange(value);
      });
  }

  private _handleValueChange(value: string): void {
    if (value !== null && value !== undefined && value !== '' && value?.length !== 0) {
      this._renderer2.addClass(this._elementRef.nativeElement, 'used');
    } else {
      this._renderer2.removeClass(this._elementRef.nativeElement, 'used');
    }
  }
}
