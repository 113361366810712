import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError, timeout } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { LogoutService } from './services/logout.service';
import { IUriResponse } from './models/uri-response';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RedirectUriService } from './services/redirect-uri.service';
import { DefaultClientService } from './services/default-client.service';
import { IDefaultClientResponse } from './models/default-client-response';

export class AppStateSetAccessToken {
  public static readonly type = '[AppState] set access token';

  constructor(public readonly payload: { token: string | null }) {}
}

export class AppStateLogout {
  public static readonly type = '[AppState] logout';
}

export class AppStateGetURIForLoggedClient {
  public static readonly type = '[AppState] get URI for logged client';

  constructor(public readonly payload: { clientId: string; redirectUri?: string; state?: string }) {}
}

export class AppStateGetDefaultClientInformation {
  public static readonly type = '[AppState] get default client information';
}

export interface AppStateModel {
  loading: boolean;
  token: string | null;
  uriResponse: IUriResponse | null;
  defaultClient: IDefaultClientResponse | null;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
    token: null,
    uriResponse: null,
    defaultClient: null,
  },
})
@Injectable()
export class AppState {
  constructor(
    private readonly _logoutService: LogoutService,
    private readonly _redirectUriService: RedirectUriService,
    private readonly _recaptchaV3Service: ReCaptchaV3Service,
    private readonly _defaultClientService: DefaultClientService,
  ) {}

  @Selector()
  public static token({ token }: AppStateModel): string | null {
    return token;
  }

  @Selector()
  public static loading({ loading }: AppStateModel): boolean {
    return loading;
  }

  @Selector()
  public static uriResponse({ uriResponse }: AppStateModel): IUriResponse | null {
    return uriResponse;
  }

  @Selector()
  public static defaultClient({ defaultClient }: AppStateModel): IDefaultClientResponse | null {
    return defaultClient;
  }

  @Action(AppStateSetAccessToken)
  public setAccessToken(
    { patchState }: StateContext<AppStateModel>,
    { payload: { token } }: AppStateSetAccessToken,
  ): void {
    patchState({ token });
  }

  @Action(AppStateLogout)
  public logout({ getState, patchState }: StateContext<AppStateModel>): Observable<void> {
    const { token } = getState();
    if (token) {
      patchState({ loading: true });
      return this._logoutService.logout().pipe(
        finalize(() => {
          patchState({ token: null, loading: false });
        }),
      );
    }
    return of(void 0);
  }

  @Action(AppStateGetURIForLoggedClient)
  public getURIForLoggedClient(
    { patchState }: StateContext<AppStateModel>,
    { payload: { clientId, redirectUri, state } }: AppStateGetURIForLoggedClient,
  ): Observable<IUriResponse> {
    patchState({ loading: true });
    return this._recaptchaV3Service.execute('importantAction').pipe(
      timeout({ first: 5000, with: () => throwError(() => 'timeout') }),
      concatMap((recaptchaKey: string) => {
        return this._redirectUriService.getURIForLoggedClient(recaptchaKey, clientId, redirectUri, state).pipe(
          tap((uriResponse: IUriResponse) => {
            patchState({ uriResponse });
          }),
          finalize(() => {
            patchState({ loading: false });
          }),
        );
      }),
    );
  }

  @Action(AppStateGetDefaultClientInformation)
  public getDefaultClientInformation({ patchState }: StateContext<AppStateModel>): Observable<IDefaultClientResponse> {
    patchState({ loading: true });
    return this._defaultClientService.getDefaultClientInformation().pipe(
      tap((defaultClient: IDefaultClientResponse) => {
        patchState({ defaultClient });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
