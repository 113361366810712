import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _history: string[] = [];

  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._history.push(event.urlAfterRedirects);
      }
    });
  }

  public goBack(): void {
    this._history.pop();
    if (this._history.length > 0) {
      this._location.back();
    } else {
      this._router.navigateByUrl('/').then();
    }
  }
}
