import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVICES_API } from '@amica-sso/data';
import { IUriResponse } from '../models/uri-response';

@Injectable({
  providedIn: 'root',
})
export class RedirectUriService {
  constructor(
    @Inject(SERVICES_API)
    private readonly _servicesApi: string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Get redirect URI for logged client
   * @param recaptchaKey
   * @param clientId
   * @param redirectUri
   * @param state
   */
  public getURIForLoggedClient(
    recaptchaKey: string,
    clientId: string,
    redirectUri?: string,
    state?: string,
  ): Observable<IUriResponse> {
    let params: HttpParams = new HttpParams({
      fromObject: {
        recaptchaKey,
        client_id: clientId,
      },
    });
    if (redirectUri) {
      params = params.append('redirect_uri', redirectUri);
    }
    if (state) {
      params = params.append('state', state);
    }
    return this._httpClient.post<IUriResponse>(`${this._servicesApi}/platform/client/redirect-uri`, null, {
      params,
    });
  }
}
