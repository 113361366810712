import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';
import { setFormErrors } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class FormErrorHandlerService {
  constructor(private readonly _toastrService: ToastrService) {}

  public showErrorToastIfExists(error: any): void {
    if (error === 'timeout') {
      this._toastrService.warning('Sieć internetowa, z której korzystasz jest zbyt słaba.', 'Koniec czasu');
    }
    this._displayErrorMessageIfExists(error);
  }

  public catchFormError(error: any, formGroup: UntypedFormGroup | FormGroup): Observable<any> {
    this.showErrorToastIfExists(error);
    if (error?.error?.code === 400) {
      setFormErrors(error?.error?.errors?.children, formGroup);
      formGroup.updateValueAndValidity();
      return of(null);
    }
    return throwError(error);
  }

  private _displayErrorMessageIfExists(error: any): void {
    if (
      error?.error?.errors?.errors &&
      Array.isArray(error?.error?.errors?.errors) &&
      error?.error?.errors?.errors.length
    ) {
      this._toastrService.error(error?.error?.errors?.errors[0]);
    }
  }
}
