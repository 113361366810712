export const environment = {
  production: false,
  development: false,
  isApplicationUnderConstruction: false,
  api: 'https://test-api-sso.amica.pl',
  upload: 'https://test-api-sso.amica.pl/uploads',
  // amica.pl
  // defaultAppClientId: 'e73237f3bd7af472c95cf88b62ae1c26',
  amicaAppClientId: 'e73237f3bd7af472c95cf88b62ae1c26',
  amicaUrl: 'https://mcstaging.amica.pl',
  mojaAmicaAppClientId: 'f5235ecac2e6259f2e455c1ae6970155',
  mojaAmicaUrl: 'https://test-moja.amica.pl',
  mojaAmicaLocalHostAddress: '',
  recaptchaV3SiteKey: '6LfI-RMiAAAAAJ71UXwAJxTYJjE1NYO7cAUjBqm-',
  googleClientId: '1066867851821-sqiib0tukv044cjrvdnl7505ejftr0c1.apps.googleusercontent.com',
  facebookClientId: '761952188438701',
  syneriseKey: 'a815559a-6053-4818-8a31-a4d185935f36',
};
