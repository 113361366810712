import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class MatchOtherValidator {
  static matchOther(otherControlName: string, isMatch = true): ValidatorFn {
    let thisControl: FormControl<string>;
    let otherControl: FormControl<string>;

    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control as FormControl<string>;
        otherControl = control.parent.get(otherControlName) as FormControl<string>;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value && isMatch) {
        return {
          matchOther: true,
        };
      } else if (otherControl.value === thisControl.value && !isMatch) {
        return {
          matchOther: true,
        };
      }

      return null;
    };
  }
}
