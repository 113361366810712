import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { SsrGuard } from './guards/ssr.guard';
import { SERVICES_API, SERVICES_CUSTOMER_TOKEN } from '@amica-sso/data';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppState } from './app.state';
import { APP_JWT_TOKEN } from './app.consts';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { Router, RouterModule } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { APP_ROUTES } from './app.routing';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { NavigationService } from './services/navigation.service';
import { throwError } from 'rxjs';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { provideNgxMask } from 'ngx-mask';
import { SYNERISE_KEY } from './tokens';

const LOCAL_STORAGE_STATE_VALUES = [APP_JWT_TOKEN];

registerLocaleData(localePl);

const NGXS_MODULES = [
  NgxsModule.forRoot([AppState], {
    developmentMode: !environment.production,
    selectorOptions: {
      suppressErrors: false,
      injectContainerState: false,
    },
    compatibility: {
      strictContentSecurityPolicy: true,
    },
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    storage: StorageOption.LocalStorage,
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    // for NgxIntlTelInputModule
    BsDropdownModule.forRoot(),
    HttpClientModule,
    HammerModule,
    RecaptchaV3Module,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
    }),
    QuicklinkModule,
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking',
    }),
    ...NGXS_MODULES,
  ],
  providers: [
    SsrGuard,
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    { provide: SERVICES_API, useValue: environment.api },
    { provide: SYNERISE_KEY, useValue: environment.syneriseKey },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => ({
        onBeforeLoad(url: URL) {
          url.searchParams.set('hl', locale);
          return { url };
        },
      }),
      deps: [LOCALE_ID],
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: SERVICES_CUSTOMER_TOKEN,
      useFactory: (store: Store) => () => store.selectSnapshot(AppState.token),
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [NavigationService],
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, {
              scopes: 'openid profile email',
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId, {
              scope: 'email',
              return_scopes: true,
              enable_profile_selector: true,
            }),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
